export const subjects = {
    "nursing": "Nursing",
    "psychology": "Psychology",
    "sociology": "Sociology",
    "healthcare": "Healthcare",
    "business": "Business",
    "management": "Management",
    "engineering": "Engineering",
    education: "Education",
    law: "Law",
    history: "History",
    literature: "Literature",
    biology: "Biology",
    chemistry: "Chemistry",
    physics: "Physics",
    mathematics: "Mathematics",
    "computer science": "Computer Science",
    "information technology": "Information Technology",
    economics: "Economics",
    finance: "Finance",
    accounting: "Accounting",
    marketing: "Marketing",
    "political science": "Political Science",
    philosophy: "Philosophy",
    religion: "Religion",
    arts: "Arts",
    music: "Music",
    film: "Film",
    theater: "Theater",
    dance: "Dance",
    architecture: "Architecture",
    sports: "Sports",
    tourism: "Tourism",
    geography: "Geography",
    anthropology: "Anthropology",
    archaeology: "Archaeology",
    linguistics: "Linguistics",
    "foreign languages": "Foreign Languages",
    other: "Other",
  };
  
  export const orderTypes = {
    essay: "Essay (any type)",
    "admission essay": "Admission Essay",
    "annotated bibliography": "Annotated Bibliography",
    analyis: "Analyis (any type)",
    "article review": "Article Review",
    article: "Article (written)",
    "book review": "Book/Movie Review",
    "business plan": "Business Plan",
    "business proposal": "Business Proposal",
    "case study": "Case Study",
    coursework: "Coursework",
    "capstone project": "Capstone Project",
    "creative writing": "Creative Writing",
    "critical thinking": "Critical Thinking",
    "discussion post": "Discussion Post",
    "lab report": "Lab Report",
    letter: "Letter/Memos",
    "literature review": "Literature Review",
    outline: "Outline",
    "personal narrative": "Personal Narrative",
    presentation: "Presentation or Speech",
    "reaction paper": "Reaction Paper",
    "reflective writing": "Reflective Writing",
    report: "Report",
    "research paper": "Research Paper",
    "research proposal": "Research Proposal",
    "systematic review": "Systematic Review",
    thesis: "Thesis/Dissertation",
    "term paper": "Term Paper",
    proposal: "Proposal",
    "powerpoint presentation": "PowerPoint Presentation",
    "powerpoint presentation with speaker notes": "PowerPoint Presentation with Speaker Notes",
    homework: "Homework",
    "online assignment": "Online Assignment",
    "multiple choice questions": "Multiple Choice Questions",
    "problem solving": "Problem Solving",
    "question and answer": "Question and Answer",
    "short answer questions": "Short Answer Questions",
    "class discussion": "Class Discussion",
    "online test": "Online Test",
    exam: "Exam",
    quiz: "Quiz",
    test: "Test",
    other: "Other",
  };
  
  export const academicLevels = {
    college: "College",
    bachelors: "Bachelor's",
    masters: "Master's",
    doctorate: "Doctorate",
  };
  
  export const citationStyles = {
    chicago: "Chicago/Turabian",
    mla: "MLA",
    apa6: "APA 6th Edition",
    apa7: "APA 7th Edition",
    harvard: "Harvard",
    ieee: "IEEE",
    other: "Other",
  };
  
  export const languages = {
    "english US": "English (US)",
    "english UK": "English (UK)",
  };
  
  export const orderStatuses = {
    pending: "Pending",
    "in progress": "In Progress",
    completed: "Completed",
    "in revision": "In Revision",
    cancelled: "Cancelled",
  };
  
  export const paymentStatuses = {
    pending: "Pending",
    "partially paid": "Partially Paid",
    paid: "Paid",
    refunded: "Refunded",
  };
  
  export const urgencyLevels = {
    low: "Low",
    medium: "Medium",
    high: "High",
  };
  